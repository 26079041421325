import { getGlobalIdentityInstance, setLoginFlowState } from "@vgno/utils";

function appendScript(commentsElement: HTMLElement | null) {
  const script = document.createElement("script");
  script.src = "https://comments.vg.no/assets/embedd.js";
  script.async = true;
  script.id = "core-comments";
  script.dataset.contentId = commentsElement!.dataset.articleId!;
  commentsElement?.appendChild(script);
}

export const init = () => {
  const commentsElement = document.getElementById("comments");

  if (
    commentsElement!.dataset.articlePosterType &&
    !["login", "login-metered"].includes(
      commentsElement!.dataset.articlePosterType,
    )
  ) {
    return;
  }

  new IntersectionObserver(
    (entries, observer) => {
      if (!entries[0].isIntersecting) return;
      observer.disconnect();
      const hasDarkmode =
        document.documentElement.dataset.colorScheme === "dark";
      const rootStyles = getComputedStyle(document.documentElement);
      window._ccConfig = {
        contentId: commentsElement!.dataset.articleId!,
        contentPublisher: "vg",
        contentType: "article",
        customSortingStrategy: "mostUpvoted",
        initialCommentsCount: 2,
        lang: "nb-no",
        login: async () => {
          const identity = await getGlobalIdentityInstance();
          if (!identity) return console.error("Identity not found");
          const state = setLoginFlowState({
            medium: "write-comments",
          });

          identity.login({
            state,
            redirectUri: identity.redirectUri,
          });
        },
        p: "vg",
        statements: {
          commentInput: {
            info: "Velkommen til VGs kommentarfelt! Vi ønsker en god og saklig debatt, så tenk deg om når du kommenterer, og husk at mange leser det du skriver. Innlegg blir i utgangspunktet ikke forhåndsmoderert, men blir løpende moderert etter publisering. Les mer her{rulesUrl} om VGs debattregler. Husk at du må bruke ditt fulle navn som brukernavn.",
          },
          iframe: {
            title: "Kommentarer",
          },
        },
        theme: {
          ctaBackgroundColor: rootStyles.getPropertyValue("--accent"),
          ctaBorderColor: "transparent",
          ctaBorderRadius: rootStyles.getPropertyValue("--border-radius-l"),
          downvotesEnabled: true,
          enableMyCommentsOnboarding: true,
          neutralBackgroundColor: hasDarkmode
            ? rootStyles.getPropertyValue("--background-secondary")
            : rootStyles.getPropertyValue("--background-primary"),
          notificationsEnabled: true,
          primaryTextColor: rootStyles.getPropertyValue("--label-primary"),

          regularFontUrl: {
            woff2:
              "https://www.vg.no/vgc/font-spesial/Inter/4.0/subsets/InterVariable.woff2",
          },
          rulesUrl: "https://www.vg.no/informasjon/debattregler/",

          secondaryTextColor: rootStyles.getPropertyValue("--label-secondary"),

          showUserCommentHistory: true,
          sortingByDownvotes: true,
          sortingOptions: ["best", "newest", "mostUpvoted", "mostDownvoted"],
        },
      };
      appendScript(commentsElement);
    },
    { rootMargin: "200%" },
  ).observe(commentsElement!);
};
